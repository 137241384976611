<template>
    <div>
        <form @submit.prevent="submitForm">
            <div class="row">
                <div class="col-6" v-for="(field, index) in fields" :key="index">
                    <country-selector v-if="field.country" v-model="form[field.key]" :$v="$v.form[field.key]" @input="handleInput(field.key, $event)"></country-selector>
                    <mercur-input
                        v-else
                        :type="field.type ? field.type : 'text'"
                        v-model="form[field.key]"
                        step="any"
                        :class="getValidationClass($v, field.key)"
                        @input="handleInput(field.key, $event)"
                    >
                        {{ field.label ? field.label : field.key }}
                        <template v-if="field.icon" #suffix><i :class="field.icon"></i></template>
                        <template #note>
                            <span class="form-error" v-if="field.range && !$v.form[field.key].between">Must be between {{ field.range[0] }} and {{ field.range[1] }}</span>
                            <span class="form-error" v-if="field.maxLength && !$v.form[field.key].maxLength">Maximum of {{ field.maxLength }} character{{ field.maxLength === 1 ? '' : 's' }}</span>
                            <span class="form-error" v-if="field.required && !$v.form[field.key].required">Value is required</span>
                        </template>
                    </mercur-input>
                </div>
                <slot :form="form"></slot>
            </div>
            <div class="text-right mt-3">
                <mercur-button class="btn" @click.native.prevent="cancel" :disabled="loading">Cancel</mercur-button>
                <mercur-button type="submit" class="btn btn-primary" :disabled="loading">Submit</mercur-button>
            </div>
        </form>
    </div>
</template>

<script>
import FormMixin from '@/mixins/Form'
import { required, between, maxLength } from 'vuelidate/lib/validators'
import CountrySelector from '@/components/elements/CountrySelector'
export default {
    name: 'FormGenerator',
    mixins: [ FormMixin ],
    components: { CountrySelector },
    props: {
        data: {
            default: () => {},
        },
        fields: {
            required: true,
            default: () => [],
        },
        url: {},
    },
    data () {
        return {
            form: JSON.parse(JSON.stringify(this.data)),
        }
    },
    validations () {
        const form = {}
        this.fields.forEach((field) => {
            form[field.key] = {}
            if (field.required) {
                form[field.key] = { required }
            }

            if (field.range) {
                form[field.key].between = between(field.range[0], field.range[1])
            }

            if (field.maxLength) {
                form[field.key].maxLength = maxLength(field.maxLength)
            }
        })
        return { form }
    },
    methods: {
        submitForm () {
            if (!this.url) {
                this.$v.$touch()
                if (this.$v && this.$v.$invalid) {
                    return
                }
                this.$emit('submit', this.form)
                return
            }

            this.submit(this.url, this.form).then(() => {
                this.$emit('submit', this.form)
            })
        },
        cancel () {
            this.$emit('cancel')
        },
        handleInput (fieldKey, value) {
            const field = this.fields.find(field => field.key === fieldKey)
            if (!field || !field.onInput) {
                return
            }
            try {
                field.onInput(value)
            } catch (e) {
                console.error(e)
            }
        },
    },
}
</script>
